import styles from "./Box.module.css";

import { useTranslation } from "react-i18next";

import { UPDATE_BOX, DELETE_BOX } from "../../../../../Mutations/Workshop";

import { useMutation } from "@apollo/client";

import { useState } from "react";

import { Input } from "../../../Global/Forms/Inputs_Spread";

import Toggle from "../../../Global/Forms/Toggle/Toggle";

import Button from "../../../Global/Forms/Button";


export default function ServiceBox ( { serviceBox, refetch } ) {


    const { t } = useTranslation();


    const [ formData, setFormData ] = useState ({

        boxName: serviceBox?.boxName || "",

        googleCalendarId: serviceBox?.googleCalendarId || "",

        active: serviceBox?.active || false

    });


    const [ updateBox, { loading: loading_update } ] = useMutation ( UPDATE_BOX );

    const [ deleteBox, { loading: loading_delete } ] = useMutation ( DELETE_BOX );


    const handleUpdate = async () => {

        try {

            await updateBox ({ variables: { id: serviceBox?.id, ...formData } });

        } catch ( error ) {

            console.log ( error );

        }

    };


    const handleDelete = async () => {

        try {

            await deleteBox ({ variables: { id: serviceBox?.id } });
            await refetch();

        } catch ( error ) {

            console.log ( error );

        }

    };


    return (

        <div className={ styles.container }>

            <div>

                <Toggle 

                    label={ t ( "Active" ) }

                    onChange={ ( e ) => setFormData ({ ...formData, active: e.target.checked }) }
                
                    checked={ formData.active }

                    disabled={ loading_update || loading_delete }

                />

            </div>

            <Input 
            
                type="text" 
                
                placeholder={ t ( "Box Name" ) }

                value={ formData.boxName }

                onChange={ ( e ) => setFormData ({ ...formData, boxName: e.target.value }) }

                disabled={ loading_update || loading_delete }
                
            />

            <Input 
              
              type="text" 
              
              placeholder={ t ( "Google Calendar ID" ) }

              value={ formData.googleCalendarId }

              onChange={ ( e ) => setFormData ({ ...formData, googleCalendarId: e.target.value }) }

              disabled={ loading_update || loading_delete }
              
            />

            <div className={ styles.action }>
                
                <Button text="Update" action={ handleUpdate } disabled={ loading_update || loading_delete } />

                <Button text="Delete" action={ handleDelete } className={ styles.delete } disabled={ loading_update || loading_delete } />
                
            </div>

        </div>

    );


};