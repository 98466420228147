import { useTranslation } from "react-i18next";

import { useState, Fragment } from "react";

import { gql, useQuery, useMutation } from "@apollo/client";

import { pdf } from "@react-pdf/renderer";

import { SEND_EMAIL } from "../../../../../Mutations/Orders";

import { SHOP_CONTACTS } from "../../../../../Queries/Contacts";

import { MANAGER } from "../../../../../Queries/Managers";

import Fieldset from "../../../Global/Forms/Fieldset";

import CenterLoader from "../../../Loaders/CenterLoader";

import DocumentInvoice from "../../Documents/Invoice";

import SelectSpread from "../../../Global/Forms/Select_Spread";

/**
 * 
 * @exports SendPDF
 * 
 * @description SendPDF component.
 * 
 * @param { Object } props - The props object.
 * 
 * @param { Object } props.state - The state object.
 * 
 * @param { Object } props.order - The order object.
 * 
 * @example <SendPDF state={ state } order={ order } />
 * 
 * @returns { JSX.Element } SendPDF
 * 
 */

export default function SendPDF ( { state, order } ) {

    const 

    { t : __ } = useTranslation (),

    { data, loading } = useQuery ( SHOP_CONTACTS ),

    { data : contactsInfo, loading : loadingContactsInfo } = useQuery ( gql`query { contactInfo }` ),

    { data: manager, loading: loadingManager } = useQuery ( MANAGER, { variables: { id: order?.userId }, fetchPolicy: "network-only" } ),

    [ sendEmail, { data : mailerData, loading : loadingMailer, error } ] = useMutation ( SEND_EMAIL ),

    [ document, setDocument ] = useState ( { documentType: "" } ),

    /**
     * 
     * @param { Object } e
     * 
     * @param { Object } documentData
     * 
     * @description send pdf document
     * 
     * @returns pdf document
     * 
     * @returns { Promise<void> }
     *
     */

    sendDocument = async ( e, documentData ) => { 
        
        e.preventDefault ();

        const blob = await pdf ( documentData ).toBlob ();

        sendEmail ( { variables: { orderId: parseInt ( order.id ), orderType: order.orderType, documentType: document.documentType, pdf: new File ( [ blob ], "document.pdf" ) } } );

    };

    /** 
     * 
     * @description 
     * 
     * Render the SendPDF component.
     * 
     * @returns { JSX.Element }
     * 
     */

    return (

        <Fieldset legend={ __ ( "send_document" ) }>

            { /** Show loader if loading or loadingContactsInfo or loadingManager is true */

                ( loading || loadingContactsInfo || loadingManager ) ? <CenterLoader /> :

                    <Fragment>

                        { /** Show error message if error is true */

                            error &&

                            <div className="errors">

                                <div className="error">

                                    { __ ( error?.message || "Internal Server Error" ) }

                                </div>

                            </div>

                        }

                        { /** Show success message if mailerData is true and document.documentType is not empty */

                            ( Boolean ( mailerData ) && document.documentType !== "" ) &&

                            <div className="success">

                                <div className="success_message">

                                    { __ ( "document_sent" ) }

                                </div>

                            </div>

                        }

                        <SelectSpread 
                            
                            id="document"

                            i18n={ true }
                            
                            action={ e => setDocument ( { ...document, documentType: e.target.value } ) }
                            
                            options={ [

                                { value: "", name: `-- ${ __ ( "select_document" ) } --` },

                                { value: "invoice", name: "invoice" },

                                { value: "receipt", name: "download_receipt" },

                                { value: "offer", name: "offer" }

                            ] }
                            
                            value={ document.documentType } 
                                
                        />

                        <button 
                        
                            className="block width_full" 
                        
                            onClick={ e => 
                            
                                sendDocument ( 
                                    
                                    e, 
                                    
                                    <DocumentInvoice 
                                    
                                        type={ document.documentType || "invoice" } 
                                    
                                        state={ state } 
                                    
                                        order={ order } 
                                    
                                        contacts={ data } 
                                    
                                        hours={ contactsInfo?.contactInfo?.working_hours || {} } 
                                        
                                        manager={ manager } /> 
                                        
                                    ) 
                                    
                            } disabled={ loadingMailer || document.documentType == "" }>

                                { __ ( "send" ) }

                        </button>

                    </Fragment>

            }

        </Fieldset>

    );

};