import { gql } from '@apollo/client';

const 

/** @description update existing order */

UPDATE_ORDER = gql`mutation UpdateOrder (

    $id: ID!

    $assembly: Boolean!

    $assemblyPrice: Float

    $mobilePhoneNr: String!

    $email: String!

    $firstName: String!

    $lastName: String!

    $deliveryMethod: String

    $paymentMethod: OrderPaymentMethodEnum

    $deliveryPrice: Float

    $city: String = "Riga"

    $countryCode: String

    $postCode: String

    $address: String

    $regNr: String

    $pvnRegNr: String

    $companyName: String

    $orderType: OrderTypeEnum!

    $status: OrderStatusEnum!

    $userId: Int!

    $productInput: [AdminOrderProductInput!]

    $orderDeliveryNotes: String

    $orderPaymentNotes: String

    $adminNotes: String

    $deliveryPlanId: Int

    $userNotes: String

    $vatIncluded: Boolean

) {

    adminUpdateOrder (

        input: {

            id: $id,

            ordersInput: {

                assembly: $assembly

                assemblyPrice: $assemblyPrice

                mobilePhoneNr: $mobilePhoneNr

                email: $email

                firstName: $firstName

                lastName: $lastName

                deliveryMethod: $deliveryMethod

                deliveryPrice: $deliveryPrice

                paymentMethod: $paymentMethod

                city: $city

                countryCode: $countryCode

                postCode: $postCode

                address: $address

                regNr: $regNr

                pvnRegNr: $pvnRegNr

                companyName: $companyName

                orderType: $orderType

                status: $status

                userId: $userId

                orderDeliveryNotes: $orderDeliveryNotes

                orderPaymentNotes: $orderPaymentNotes

                adminNotes: $adminNotes

                deliveryPlanId: $deliveryPlanId

                userNotes: $userNotes 

                vatIncluded: $vatIncluded

            }

            productInput: $productInput

        }

    ) {

        order {

            id

        }

    }

}`,

/** @description delete existing order */

DELETE_ORDER = gql`mutation DeleteOrder (

    $id: ID!

) {

    adminDeleteOrder (

        input: {

            id: $id

        }

    ) {

        order {

            id

        }

    }

}`,

/** @description create order */

CREATE_ORDER = gql`mutation CreateOrder (

    $assembly: Boolean!

    $assemblyPrice: Float

    $mobilePhoneNr: String!

    $email: String!

    $firstName: String!

    $lastName: String!

    $deliveryMethod: String

    $paymentMethod: OrderPaymentMethodEnum

    $city: String

    $countryCode: String

    $postCode: String

    $address: String

    $deliveryPrice: Float

    $regNr: String

    $pvnRegNr: String

    $companyName: String

    $orderType: OrderTypeEnum!

    $status: OrderStatusEnum!

    $userId: Int!

    $productInput: [AdminOrderProductInput!]

    $orderDeliveryNotes: String

    $orderPaymentNotes: String

    $adminNotes: String

    $deliveryPlanId: Int

    $userNotes: String

    $vatIncluded: Boolean

) {

    adminCreateOrder (

        input: {

            ordersInput: {

                assembly: $assembly

                mobilePhoneNr: $mobilePhoneNr

                email: $email

                firstName: $firstName

                lastName: $lastName

                deliveryMethod: $deliveryMethod

                paymentMethod: $paymentMethod

                city: $city

                countryCode: $countryCode

                postCode: $postCode

                address: $address

                deliveryPrice: $deliveryPrice

                regNr: $regNr

                pvnRegNr: $pvnRegNr

                companyName: $companyName

                orderType: $orderType

                status: $status

                userId: $userId

                orderDeliveryNotes: $orderDeliveryNotes

                orderPaymentNotes: $orderPaymentNotes

                adminNotes: $adminNotes

                deliveryPlanId: $deliveryPlanId

                assemblyPrice: $assemblyPrice

                userNotes: $userNotes

                vatIncluded: $vatIncluded

            }

            productInput: $productInput

        }

    ) {

        order {

            id

        }

    }

}`,

/** @description send e-mail */

SEND_EMAIL = gql`mutation AdminCreateOrderMailer (

    $orderId: Int!

    $orderType: OrderTypeEnum

    $pdf: Upload

    $documentType: String

) {

    adminCreateOrderMailer (

        input: {

            orderId: $orderId

            orderType: $orderType

            documentType: $documentType

            pdf: $pdf

        }

    ) { clientMutationId }

}`;

/** @export mutations */

export { 

    UPDATE_ORDER,
    
    DELETE_ORDER,

    CREATE_ORDER,

    SEND_EMAIL

}