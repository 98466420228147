/** @module         Apollo */

import { gql }      from "@apollo/client";

const 

/** @name ORDERS */

ORDERS = gql`query

    fetchOrders (

        $stringSearch: String,

        $after: String,

        $before: String,

        $first: Int,

        $last: Int,

        $currentManagerOrder: Boolean,

        $onlyStorefrontOrders: Boolean,

        $sortParams: AdminTableColumnSortingInput

        $orderType: [OrderTypeEnum!]

        $status: [OrderStatusEnum!]

        $deliveryMethod: DeliveryMethodEnum

        $paymentMethod: OrderPaymentMethodEnum

        $dateRangeStart: ISO8601Date

        $dateRangeEnd: ISO8601Date

        $managerOrder: Int

    ) { 
        
        adminOrders (

            first: $first

            last: $last

            before: $before

            after: $after

            stringSearch: $stringSearch

            currentManagerOrder: $currentManagerOrder

            onlyStorefrontOrders: $onlyStorefrontOrders

            sortParams: $sortParams

            orderType: $orderType

            status: $status

            deliveryMethod: $deliveryMethod

            paymentMethod: $paymentMethod

            dateRangeStart: $dateRangeStart

            dateRangeEnd: $dateRangeEnd

            managerOrder: $managerOrder

        ) {

            edges {

                node {

                    id

                    adminNotes

                    assembly

                    assemblyPrice

                    bankIban

                    bankNr

                    bankSwift

                    city

                    companyName

                    countryCode

                    createdAt

                    customLegalFields

                    deliveryMethod

                    deliveryPrice

                    faxNr

                    hashId

                    id

                    mobilePhoneNr

                    orderProductVendors {
        
                        count
                    
                        id
                    
                        markupVersionId
                    
                        orderId
                    
                        price

                        discount

                        originalPrice

                        vendorName
                    
                        product {
                            
                            id
                            
                            currentImage
                            
                            model
            
                            productType
            
                            priceList

                            width

                            height

                            diameter

                            voltage

                            current

                            capacity

                            isCTire

                            season

                            runFlat

                            xl

                            specifications

                            studs

                            year

                            prices {

                                originalPrice

                            }
                                        
                            manufacturer {
                                
                                id
                                
                                name
                                
                            }
            
                            productVendors {
            
                                qty
            
                                qtyDays
            
                                qtyHour
            
                                qtyWeeks
            
                            }

                            vendors {

                                id

                                name

                            }
                        
                        }
                    
                        productId
                    
                        productVendorId
                    
                        productVersionId
                    
                    }

                    orderType

                    paid

                    paidAt

                    paymentMethod

                    phoneNr

                    postCode

                    price

                    pvnRegNr

                    regNr

                    status

                    updatedAt

                    userId

                    userNotes

                    orderPaymentNotes

                    orderDeliveryNotes

                    firstName

                    lastName

                    invoiceNumber

                    lastEditedBy

                }
                    
            }

            pageInfo { 
            
                hasNextPage 
                
                hasPreviousPage 
                
                startCursor 
                
                endCursor 
            
            }

        } 
    
    }`,

/** @name ORDER_PREVIEW */

ORDER_PREVIEW = gql`query fetchOrderPreview ( $id: ID! ) {

    adminOrder ( id: $id ) {

        id

        adminNotes

        assembly

        assemblyPrice

        bankIban

        bankNr

        bankSwift

        city

        companyName

        countryCode

        createdAt

        customLegalFields

        deliveryMethod

        deliveryPrice

        faxNr

        hashId

        id

        mobilePhoneNr

        paid

        paidAt

        paymentMethod

        phoneNr

        postCode

        price

        pvnRegNr

        regNr

        status

        updatedAt

        userNotes

        orderPaymentNotes

        orderDeliveryNotes

        firstName

        lastName

        invoiceNumber

        vatIncluded

    }

}`,

/** @name  */

ORDER = gql`query fetchOrder ( $id: ID! ) {

    adminOrder ( id: $id ) {

        address

        email

        firstName

        lastName
    
        adminNotes
        
        assembly
        
        assemblyPrice

        discountPrice
        
        bankIban
        
        bankNr
        
        bankSwift
        
        city
        
        companyName
        
        countryCode
        
        createdAt
        
        customLegalFields
        
        deliveryMethod
        
        deliveryPrice

        deliveryPlanId
        
        faxNr
        
        hashId
        
        id

        orderHistories {

            comments
            
            createdAt
            
            deliveryMethod
            
            editedBy
            
            paymentMethod
            
            status

            communication

        }
        
        mobilePhoneNr
        
        orderProductVendors {
        
            count
        
            id
        
            markupVersionId
        
            orderId
        
            price

            discount

            originalPrice

            vendorName
        
            product {
                
                id
                
                currentImage
                
                model

                productType

                priceList

                width

                height

                diameter

                voltage

                current

                capacity

                pitchCircleDiameter

                offset

                centreBore

                isCTire

                loadIndex

                speedRating

                season

                runFlat

                xl

                specifications

                studs

                year

                prices {

                    originalPrice

                }
                
                manufacturer {
                    
                    id
                    
                    name
                    
                }

                productVendors {

                    qty

                    qtyDays

                    qtyHour

                    qtyWeeks

                }
            
            }
        
            productId
        
            productVendorId
        
            productVersionId
        
        }
        
        orderType
        
        paid
        
        paidAt
        
        paymentMethod
        
        phoneNr
        
        postCode
        
        price
        
        pvnRegNr
        
        regNr
        
        status
        
        updatedAt
        
        userId
        
        userNotes

        orderPaymentNotes

        orderDeliveryNotes

        invoiceNumber

        lastEditedBy

        vatIncluded

    }

}`,

/** @name ORDER_CUSTOMERS */

ORDER_CUSTOMERS = gql`query fetchOrderCustomers (

    $page: Int

    $items: Int

    $search: String

) {

    adminClients (

        page: $page

        items: $items

        stringSearch: $search

    ) {

        items {

            companyName

            companyInformation

            regNr

            banks {

                name

                number

                swift

            }

            firstName

            lastName

            fullName

            addresses {

                id

                city

                country

                postalCode

                address

                comment

            }

            mobilePhoneNr

            email

            deliveryPlanId

            showVat

        }

    }

}`,

/** @name ORDER_PRICE_CALCULATOR */

ORDER_PRICE_CALCULATOR = gql`query calculateOrderPrice ( 
    
    $productParams: [JSON!]!

    $clientId: Int

    $deliveryPlanId: Int

    $deliveryPrice: Float!

    $servicePrice: Float!

    $vat: Boolean!

    $city: String!

    $countryCode: String!
    
) {

    orderPriceCalculator (

        productParams: $productParams

        clientId: $clientId

        deliveryPlanId: $deliveryPlanId

        deliveryPrice: $deliveryPrice

        city: $city

        countryCode: $countryCode

        servicePrice: $servicePrice

        vat: $vat

    )

}`,

/** @name DELIVERY_PLANS */

DELIVERY_PLANS = gql`query { deliveryPlans { id, value } }`,

/** @name ORDER_STATUS_ENUM */

ORDER_STATUS_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "OrderStatusEnum" ) {

        enumValues {

            name

        }

    }

}`,

/** @name ORDER_TYPE_ENUM */

ORDER_TYPE_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "OrderTypeEnum" ) {

        enumValues {

            name

        }

    }

}`,

/** @name PAYMENT_METHODS */

PAYMENT_METHODS_ENUM = gql`query introspectTaskStateEnumType {

    __type ( name: "OrderPaymentMethodEnum" ) {

        enumValues {

            name

        }

    }

}`;

/** @exports Queries */

export {

    ORDERS,

    ORDER,

    ORDER_PREVIEW,

    ORDER_PRICE_CALCULATOR,

    DELIVERY_PLANS,

    ORDER_STATUS_ENUM,

    ORDER_TYPE_ENUM,

    ORDER_CUSTOMERS,

    PAYMENT_METHODS_ENUM

}